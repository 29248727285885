import { EProductTags } from 'shared/enums/productTags.enum';
import { IProductSpecification } from '../models/product.model';

export function getProductsTagsByPurpose(
  specifications: Array<IProductSpecification>,
  purpose: EProductTags,
  sequence?: number,
): Array<IProductSpecification> {
  return (specifications || [])
    .filter((specification) => specification?.purpose?.includes(purpose))
    .slice(0, sequence);
}
